import React, { FC } from 'react';
import { Button, RichText } from '@marriott/mi-ui-library';
import { carddetails } from '../__mock__/CardDetails.mock';
import clsx from 'clsx';
import { PointsCard } from '../../../molecules/PointsCard';
import { CardDetailsRightProps, PointsListProps } from './CardDetailsRight.types';
import { StyledCardDetailsRight } from './CardDetailsRight.styles';

export const CardDetailsRight: FC<CardDetailsRightProps> = (props: CardDetailsRightProps) => {
  const {
    componentId,
    cardDescription,
    pointsList,
    cardType,
    pointsHeader,
    showAccordion,
    referCta,
    referTrackingProperties,
  } = props;

  const raftrackingProperties = {
    trackingContentPosition: referTrackingProperties?.raftrackingContentPosition,
    trackingDescription: referTrackingProperties?.raftrackingDescription,
    trackingOfferType: referTrackingProperties?.raftrackingOfferType,
    trackingTag: referTrackingProperties?.raftrackingTag,
    merchandisingCategory: referTrackingProperties?.rafmerchandisingCategory,
    atCCeVar48: referTrackingProperties?.rafatCCeVar48,
    additionalTrackingVariables: referTrackingProperties?.rafadditionalTrackingVariables,
    clickTrack: referTrackingProperties?.rafclickTrack === 'true',
    impressionTrack: referTrackingProperties?.rafimpressionTrack === 'true',
    impressionCount: referTrackingProperties?.rafimpressionCount === 'true',
    isCoBrand: true,
  };
  const raflinkType = referCta?.openReferLinkInNewTab ? 'external' : 'internal';
  const rafclickTrackValue = `${referTrackingProperties?.raftrackingContentPosition}|${referTrackingProperties?.raftrackingDescription}|${raflinkType}|${referTrackingProperties?.rafadditionalTrackingVariables}`;

  return (
    <StyledCardDetailsRight
      className={clsx(
        'col-xl-8 col-lg-7 col-md-8 col-12 mb-lg-2 pr-md-3 pr-lg-5 mr-lg-2 px-0 flex-column',
        showAccordion ? 'd-flex align-items-center' : 'd-none d-md-block'
      )}
    >
      <div className="text-left">
        <RichText
          {...carddetails}
          text={pointsHeader}
          componentId={'mobile' + componentId}
          customClass="text-left mb-3 mb-md-2 mt-2 mt-md-0"
        />
        {pointsList &&
          pointsList.map((list: PointsListProps, key: number) => {
            return (
              <PointsCard
                isOnSpend={true}
                number={list.pointDetailsNoOfPoints}
                content={list.pointDetailsDescription}
                cardType={cardType}
                styleClass="mb-3 mb-lg-2 ml-md-3"
                pointsForCarddetails={true}
                size={'small'}
                key={key}
              />
            );
          })}
      </div>
      <RichText
        {...carddetails}
        text={cardDescription}
        componentId={'accordion-' + componentId}
        customClass="mt-2 mt-md-4 mt-lg-3"
      />

      <div
        className={clsx('refer-friend-link col-12 p-0 text-align-left  d-none mt-lg-2 ')}
        data-creditcardCode={referCta?.creditCardCode}
      >
        <Button
          isLink={true}
          href={referCta?.referLinkUrl}
          target={referCta?.openReferLinkInNewTab === 'true' ? '_blank' : '_self'}
          className={clsx('m-link pb-1 mb-1 mt-1 mt-3 mr-0', 'track-completed')}
          linkType={raflinkType}
          buttonCopy={referCta?.referLinkText}
          trackingProperties={raftrackingProperties}
          custom_click_track_value={rafclickTrackValue}
          linkAriaLabelOpenNewWindow={referCta?.openReferLinkInNewTab ? 'Open in new tab' : ''}
        ></Button>
      </div>
    </StyledCardDetailsRight>
  );
};
