import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { Icon, Text, Types, Modal, Heading } from '@marriott/mi-ui-library';
import { CardFeatureDetailProps, CardFeatureRowProps } from './CardFeatureRow.types';
import { StyledCardFeatureRow } from './CardFeatureRow.styles';
import { getColumnClass } from '../../../modules/utils';

export const CardFeatureRow: FC<CardFeatureRowProps> = (props: CardFeatureRowProps) => {
  const {
    featuresSubList,
    columnLayout,
    featureTitle,
    infoModalEnabled,
    modalTitle,
    modalDescription,
    modalAriaLabel,
    featureTitleFontSize = Types.size.large,
    isTextCentered = true,
    isTickIconFilled = true,
  } = props;

  const iconClass = 'icon-information ml-1 icon-m';
  const headerContainerClass = 'col-12 py-2 d-flex justify-content-center align-items-baseline';
  const modalProps = {
    className: 'd-flex justify-content-center',
    secondaryClassName: 'm-auto',
  };
  const [popupOpenState, setPopupOpenState] = useState<boolean>(false);

  const toggleModal = () => {
    setPopupOpenState(!popupOpenState);
  };

  return (
    <StyledCardFeatureRow>
      <div className={clsx(headerContainerClass, 'feature-header')}>
        <Heading
          titleText={featureTitle}
          variation={Types.headingType.subtitle}
          fontSize={featureTitleFontSize}
          customClass={clsx('m-0 my-lg-1')}
        />
        {infoModalEnabled && (
          <div onClick={toggleModal} role="button" tabIndex={0} aria-label={modalAriaLabel} data-testid="myDiv">
            <Icon iconClass={clsx(iconClass, 'feature-icon')} />
          </div>
        )}
      </div>
      {popupOpenState && (
        <Modal {...modalProps} show={true}>
          <Modal.Header
            labelText={modalTitle}
            popupHeaderOnCLoseFunc={toggleModal}
            className="pb-4 pr-4 pl-md-5 pl-4 pt-md-5 pb-lg-4 pr-lg-4"
          />

          <Modal.Body className="m-modal-body pt-0 pl-4 pl-md-5 pt-lg-5 mt-lg-1">
            <div
              className={clsx('m-modal-content mt-2 mt-md-0 mb-0')}
              dangerouslySetInnerHTML={{ __html: modalDescription }}
            ></div>
          </Modal.Body>

          <div className={clsx('m-modal-scrim')}></div>
        </Modal>
      )}
      <div className="my-lg-5 my-3 feature-options row">
        {featuresSubList &&
          featuresSubList.map((option: CardFeatureDetailProps, key: number) => {
            const {
              featureSubtitle,
              featureDescription,
              cardType,
              featureType,
              featureDescriptionTickmark,
              cardName,
              featureAriaLabel,
              featureIcon,
              featureIconColor,
            } = option;
            return (
              <div
                className={clsx(
                  getColumnClass(columnLayout, cardType),
                  cardType === 'business' ? 'd-none d-md-block' : '',
                  'pr-md-5 pl-md-2 px-1',
                  { 'text-center': isTextCentered }
                )}
                data-cardtype={cardType}
                key={key}
              >
                {cardName && <span className="sr-only">{cardName}</span>}
                {featureType === 'textField' ? (
                  <>
                    {featureSubtitle && (
                      <div
                        className={clsx(featureIcon && 'd-flex align-items-center', 'm-0 feature-item mb-lg-3 mb-2', {
                          'justify-content-center': isTextCentered,
                        })}
                      >
                        {featureIcon && <Icon iconClass={clsx('icon-l mr-2', featureIcon, featureIconColor)} />}
                        <Heading
                          titleText={featureSubtitle}
                          variation={Types.headingType.subtitle}
                          fontSize={Types.size.medium}
                          customClass={clsx('mb-0')}
                        />
                      </div>
                    )}
                    {featureDescription && (
                      <Text
                        element={Types.tags.div}
                        fontSize={Types.size.extraSmall}
                        copyText={featureDescription}
                        customClass={clsx('feature-option-info')}
                      />
                    )}
                  </>
                ) : featureType === 'enableTickMark' ? (
                  <>
                    <Icon
                      iconClass={clsx(
                        getColumnClass(columnLayout, cardType),
                        isTickIconFilled ? 'icon-success-fill' : 'icon-success',
                        'icon-l'
                      )}
                      key={key}
                    />
                    {featureAriaLabel && <span className="sr-only">{featureAriaLabel}</span>}
                    {featureDescriptionTickmark && (
                      <Text
                        element={Types.tags.div}
                        fontSize={Types.size.extraSmall}
                        copyText={featureDescriptionTickmark}
                        customClass={clsx('feature-option-info mt-lg-3 mt-2')}
                      />
                    )}
                  </>
                ) : featureType === 'enableHyphen' ? (
                  <>
                    <Icon iconClass={clsx(getColumnClass(columnLayout, cardType), 'icon-minus')} key={key} />
                    {featureAriaLabel && <span className="sr-only">{featureAriaLabel}</span>}
                  </>
                ) : (
                  <div className={clsx(getColumnClass(columnLayout, cardType))}></div>
                )}
              </div>
            );
          })}
      </div>
    </StyledCardFeatureRow>
  );
};
