import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

//THIS STYLE TO BE REMOVED ONCE ACCORDION ISSUE IS RESOLVED
export const StyledCardDetailsRight = styled.div`
  //This style is added temporarily to override the accordion classes in Rich Text component.
  .collapsed {
    border-top: none !important;
  }
  // RTE styles goes here
  p:has(> span.t-subtitle-m),
  p:has(> span.content-margin) {
    margin-bottom: 8px;
  }
  li:has(> span.t-font-s) {
    margin-bottom: 4px;
  }
  table {
    vertical-align: top;
    width: 100%;
    display: inline-table;
    @media ${baseVariables.mediaQuery.lg} {
      width: 49%;
      display: inline-block;
    }
    td {
      width: 57%;
      padding: 0 ${toRem(25)} ${toRem(8)} 0;
      vertical-align: top;
      @media ${baseVariables.mediaQuery.md} {
        padding-right: ${toRem(28)};
      }
    }
  }
  .number {
    font-family: ${baseVariables.font.fontSwissCondensed};
  }
`;
