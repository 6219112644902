// Imports for external libraries go here.
import { clsx } from 'clsx';

// Imports for internal (to the monorepo) libraries go here
import { Types, Text, Eyebrow, Label } from '@marriott/mi-ui-library';
import { StyledAwardsGalleryRow } from './AwardsGalleryRow.styles';
import { AwardListProps, AwardTileProps, AwardsGalleryRowProps } from './AwardsGalleryRow.types';

export const AwardsGalleryRow = (props: AwardsGalleryRowProps) => {
  const { allAwardsList, variation, year } = props;
  const awardContentClass = variation === '1' || variation === '2' ? ' px-md-5' : ' px-md-0 ';
  return (
    <StyledAwardsGalleryRow>
      <div className={clsx('d-flex justify-content-center', 'text-center pt-0 pb-md-5 pb-3 pb-md-5 mx-auto mb-lg-2')}>
        <div className={clsx('award-gallery-year-border mt-2 mx-5')}></div>
        <Eyebrow text={year} />
        <div className={clsx('award-gallery-year-border mt-2 mx-5')}></div>
      </div>
      <div className={clsx('row', variation === '2' || variation === '3' ? 'pb-md-4 pb-3 pb-md-0' : '')}>
        {allAwardsList.length &&
          allAwardsList.map((eachColumnAwards: AwardListProps, key: number) => {
            const listOfAwards = eachColumnAwards.awardDetailsList;
            return (
              <div
                className={clsx(
                  variation === '1'
                    ? 'col-12'
                    : variation === '2'
                    ? 'col-6 award-gallery-seperator award-gallery-seperator-even'
                    : 'col-4 award-gallery-seperator award-gallery-seperator-odd'
                )}
                key={key}
              >
                {listOfAwards.length ? (
                  listOfAwards.map((eachAward: AwardTileProps, key: number) => {
                    const { awardTitle, awardDescription } = eachAward;
                    return (
                      <div className={clsx(awardContentClass, 'mx-md-3 mx-0 px-0 pb-5 mb-lg-3')} key={key}>
                        <Label
                          labelText={awardTitle}
                          element={Types.tags.div}
                          variation={Types.TypographyColorVariations.Alternate}
                          size={Types.size.small}
                          customClass={clsx('text-center')}
                        />
                        <Text
                          copyText={awardDescription}
                          fontSize={Types.size.small}
                          element={Types.tags.div}
                          customClass={clsx('text-center mt-2 mx-0')}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div></div>
                )}
              </div>
            );
          })}
      </div>
    </StyledAwardsGalleryRow>
  );
};
