// Styles for CardFeatureRow go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledCardFeatureRow = styled.div`
  .feature {
    &-header {
      background: ${baseVariables.color.memberSolidAlt};
      border: ${toRem(1)} solid ${baseVariables.color.neutral20};
    }
    &-icon {
      cursor: pointer;
    }
    &-item {
      min-height: ${toRem(16)};
    }
  }
`;
