// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const carddetails: any = {
  pointsList: [
    {
      pointDetailsNoOfPoints: '6X',
      pointDetailsDescription:
        'Earn 6 Marriott Bonvoy® points for every $1 spent at hotels participating in Marriott Bonvoy®.',
    },
    {
      pointDetailsNoOfPoints: '2X',
      pointDetailsDescription:
        'Earn 3 Marriott Bonvoy® points for every $1 on the first $6,000 spent in combined purchases each year on gas stations, grocery stores, and dining.',
    },
    {
      pointDetailsNoOfPoints: '3X',
      pointDetailsDescription: 'Earn 2 Marriott Bonvoy® points for every $1 spent on all other eligible purchases.',
    },
  ],
  componentId: 'cont1',
  altText: 'alt',
  dataLayer: {
    mr_prof_authentication_state: 'authenticated',
    mr_prof_cc_all: 'CH-PREP',
  },
  referCta: {
    creditCardCode: 'CH-PREP',
    referLinkText: 'Refer a friend',
  },
  cardSummary:
    '<p><span class="t-title-s"><span class="points-underlined">3</span></span><span class="t-subtitle-m">&nbsp;Free Night Awards</span></p><p><span class="t-font-xs">Earn 95,000 Marriott Bonvoy bonus points after you use your new Card to make $6,000 in purchases within the first 3 moths. Terms Apply.</span></p>\r\n',
  imagePath: 'https://www.marriott.com/content/dam/mcom-familyofcards/New-Bold.png',
  pointsHeader: '<p>Points Header</p>\r\n',
  cardType: 'personal',
  impressionTrack: 'true',
  linkText: 'learn more',
  cardDescription:
    '<p> <span class="t-subtitle-l">Additional Benefits</span></p> <p class="t-subtitle-m">1 Free Night Award</p> <p class="t-font-s">Receive 1 Free Night Award every year after your Card account anniversary.Receive 1 Free Night Award every year after your Card account anniversary. Award can be used for one night (redemption level at or under 35,000 Marriott Bonvoy points) at a participating hotel. Certain hotels have resort fees.</p><p class="t-subtitle-m">Automatic Marriott Bonvoy Silver Elite Status<br></p><p class="t-font-s">You will automatically receive Silver Elite Status each account anniversary year as a cardmember.</p><p class="t-subtitle-m">15 Elite Night Credits<br></p><p class="t-font-s">Each calendar year you can receive 15 Elite Night Credits towards the next level of Marriott Bonvoy Elite status. Limitations apply per Marriott Bonvoy member account. Terms apply.</p><p class="t-subtitle-m">Earn Elite Night Credits<br></p><p class="t-font-s">Earn 1 credit toward Elite Status for every $5,000 you spend with no cap.</p>\r\n',
  ':type': 'mi-aem-cobrand/components/content/carddetails',
  openInaNewTab: 'true',
  clickTrack: 'true',
  linkUrl: '#',
  header: 'Marriott Bonvoy Boundless® Credit Card from Chase',
  id: 'carddetails-7c4a3ef053',
  merchandisingCategory: 'nonCobrand',
  impressionCount: 'true',
  expandAccordionForMobile: false,
};
