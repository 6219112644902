// Styles for CobrandCardOverview go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const styleConstants = {
  cardDimensionDefault: {
    desktopWidth: 554,
    mobileWidth: 307,
  },
};

export const StyledCardOverview = styled.div`
  .card-overview {
    &-bordered {
      border: ${baseVariables.border.borderWidth02} ${baseVariables.border.borderStyleDefault}
        ${baseVariables.color.neutral20};
      border-radius: ${toRem(16)};
      box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
      position: relative;
      text-wrap: wrap;
      width: 100%;
      white-space: normal;
      max-width: ${toRem(styleConstants.cardDimensionDefault.mobileWidth)};
      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${toRem(styleConstants.cardDimensionDefault.desktopWidth)};
        border-radius: ${baseVariables.border.borderInset02};
      }
      .displayOnDesktop {
        display: none;
        @media ${baseVariables.mediaQuery.lg} {
          display: inline-block;
        }
      }
      hr {
        border: none;
        border-bottom: ${baseVariables.border.borderWidth02} ${baseVariables.border.borderStyleDefault}
          ${baseVariables.color.neutral30};
        width: 100%;
      }
    }
    &__image {
      max-width: ${toRem(100)};
      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${toRem(196)};
      }
    }
    &-header {
      p {
        margin-bottom: 0;
        @media ${baseVariables.mediaQuery.lg} {
          margin-bottom: ${toRem(8)};
        }
      }
    }
    &-subheader {
      p {
        margin-bottom: ${toRem(8)};
      }

      .link-wrapper {
        @media ${baseVariables.mediaQuery.lg} {
          margin-top: ${toRem(8)};
          display: inline-block;
        }
      }
      .custom-link {
        text-decoration: underline;
        display: block;
        @media ${baseVariables.mediaQuery.lg} {
          display: inline;
        }
      }
      .points-underlined {
        font-family: ${baseVariables.font.fontSwissCondensed};
        border-bottom: ${toRem(5)} ${baseVariables.border.borderStyleDefault} ${baseVariables.color.accent10};
        display: block;
        width: fit-content;
        margin: ${toRem(4)} auto ${toRem(8)} auto;
        @media ${baseVariables.mediaQuery.md} {
          margin: ${toRem(8)} auto ${toRem(12)} auto;
        }
        @media ${baseVariables.mediaQuery.lg} {
          margin: 0;
          display: inline;
        }
      }
      &.card-overview-richtext {
        & > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-grow: 1;
        }
      }
    }
  }
`;
