// Styles for AlertBanner go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
export const StyledDataCell = styled.div`
  .cell-wrapper {
    height: 100%;
  }
  .card-image {
    width: ${toRem(108)};
  }
  .icon-block {
    span[class^='icon-'] {
      color: ${baseVariables.color['accent10']};
      font-size: ${toRem(36)};
    }
  }
  .icon-wrapper {
    border-radius: 50%;
    width: ${toRem(24)};
    height: ${toRem(24)};
  }
  .cell-border {
    border: ${toRem(1)} ${baseVariables.border.borderStyleDefault} #f0f0f0;
  }
  .gold-status {
    background: linear-gradient(72deg, #b99362 -4.94%, #6f4818 11.22%, #6f4818 67.24%, #785427 87.54%, #b99362 102.36%);
  }
  .platinum-status {
    background: linear-gradient(73deg, #606267 3.41%, #43454a 18.01%, #43454a 78.83%, #8a898b 101.62%, #a9a5a5 106.62%);
  }
  .silver-status {
    background: linear-gradient(72deg, #70758d 0.14%, #515566 11.45%, #414451 75.73%, #70758d 93.48%, #99a3cf 109.67%);
  }
  .gold-status,
  .platinum-status,
  .silver-status {
    text-decoration: underline;
    text-decoration-color: ${baseVariables.color.merch50};
    box-shadow: 0 ${toRem(2)} ${toRem(10)} 0 rgba(0, 0, 0, 0.07);
  }
  //Styles for RTE
  .cell-wrapper p {
    margin-bottom: 0;
  }
  .link-wrapper a {
    margin-bottom: ${toRem(16)};
    display: block;
  }
  .points-underlined {
    font-family: ${baseVariables.font.fontSwissCondensed};
    border-bottom: ${toRem(5)} ${baseVariables.border.borderStyleDefault} ${baseVariables.color.accent10};
    width: fit-content;
    margin: ${toRem(4)} auto ${toRem(8)} auto;
    @media ${baseVariables.mediaQuery.md} {
      margin: ${toRem(8)} auto ${toRem(12)} auto;
    }
    @media ${baseVariables.mediaQuery.lg} {
      margin: 0;
      display: inline;
    }
  }
  .tertiary-links a {
    text-decortion: underlined;
  }
`;
