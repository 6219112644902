// Styles for CobrandCardOverview go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const styleConstants = {
  imageDimensionVertical: {
    desktopWidth: 190,
    mobileWidth: 190,
    tabletWidth: 202,
  },
  imageDimensionDefault: {
    desktopWidth: 221,
    tabletWidth: 171,
    mobileWidth: 110,
  },
  cardDimensionDefault: {
    desktopWidth: 370,
    tabletWidth: 343,
    mobileWidth: 320,
  },
  cardDimensionThree: {
    desktopWidth: 352,
  },
  oneColumn: {
    desktopTop: -94,
    tabletTop: -70,
    mobileTop: -46,
  },
  threeColumn: {
    tabletTop: -38,
  },
};

export const StyledCardOverview = styled.div`
  .card-overview {
    &-bordered {
      border: ${baseVariables.border.borderWidth02} ${baseVariables.border.borderStyleDefault}
        ${baseVariables.color.neutral20};
      border-radius: ${toRem(16)};
      box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
      position: relative;
      text-wrap: wrap;
      width: 100%;
      white-space: normal;
      max-width: ${toRem(styleConstants.cardDimensionDefault.mobileWidth)};
      @media ${baseVariables.mediaQuery.md} {
        max-width: ${toRem(styleConstants.cardDimensionDefault.tabletWidth)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${toRem(styleConstants.cardDimensionDefault.desktopWidth)};
        border-radius: ${baseVariables.border.borderInset02};
      }
      &__image {
        max-width: ${toRem(202)};
      }
      &__default {
        max-width: ${toRem(styleConstants.cardDimensionDefault.mobileWidth)};
        @media ${baseVariables.mediaQuery.md} {
          max-width: ${toRem(styleConstants.cardDimensionDefault.tabletWidth)};
        }
        @media ${baseVariables.mediaQuery.lg} {
          max-width: ${toRem(styleConstants.cardDimensionDefault.desktopWidth)};
        }
      }
      .displayOnDesktop {
        display: none;
        @media ${baseVariables.mediaQuery.lg} {
          display: inline-block;
        }
      }
      hr {
        border: none;
        border-bottom: ${baseVariables.border.borderWidth02} ${baseVariables.border.borderStyleDefault}
          ${baseVariables.color.neutral30};
        width: 100%;
      }
    }
    &-two-cards {
      @media ${baseVariables.mediaQuery.lg} {
        width: ${toRem(styleConstants.cardDimensionDefault.desktopWidth)};
      }
    }
    &-three-cards {
      @media ${baseVariables.mediaQuery.lg} {
        width: ${toRem(styleConstants.cardDimensionThree.desktopWidth)};
      }
    }
    hr {
      border: none;
      border-bottom: ${baseVariables.border.borderWidth02} ${baseVariables.border.borderStyleDefault}
        ${baseVariables.color.neutral30};
      width: 100%;
    }
    &-header {
      p {
        margin-bottom: ${toRem(8)};
        @media ${baseVariables.mediaQuery.lg} {
          margin-bottom: ${toRem(16)};
        }
      }
    }
    &-subheader {
      p {
        &:first-child {
          margin-bottom: ${toRem(8)};
          @media ${baseVariables.mediaQuery.md} {
            margin-bottom: ${toRem(16)};
          }
          @media ${baseVariables.mediaQuery.lg} {
            margin-bottom: ${toRem(32)};
          }
        }
        &:last-child {
          margin-top: auto;
        }
      }
      & > div {
        display: flex;
        flex-direction: column;
      }
      .line-separator {
        display: none;
        @media ${baseVariables.mediaQuery.lg} {
          display: inline;
        }
      }
      .link-wrapper {
        @media ${baseVariables.mediaQuery.lg} {
          margin-top: ${toRem(8)};
          display: inline-block;
        }
      }
      .custom-link {
        text-decoration: underline;
        display: block;
        @media ${baseVariables.mediaQuery.lg} {
          display: inline;
        }
      }
      .points-underlined {
        font-family: ${baseVariables.font.fontSwissCondensed};
        border-bottom: ${toRem(5)} ${baseVariables.border.borderStyleDefault} ${baseVariables.color.accent10};
        display: block;
        width: fit-content;
        margin: ${toRem(4)} auto ${toRem(8)} auto;
        @media ${baseVariables.mediaQuery.md} {
          margin: ${toRem(8)} auto ${toRem(12)} auto;
        }
        @media ${baseVariables.mediaQuery.lg} {
          margin: 0;
          display: inline;
        }
      }
      .middle-align {
        vertical-align: unset;
        @media ${baseVariables.mediaQuery.lg} {
          vertical-align: super;
        }
      }
    }
    &-foc {
      height: 100%;
      &-wrapper,
      &-wrapper .card-overview-wrapper {
        width: 100%;
        .card-overview-subheader {
          & > div {
            justify-content: center;
            flex-grow: 1;
          }
        }
      }
      p {
        &:last-child {
          margin: 0;
        }
      }

      &-image {
        margin-top: ${styleConstants.threeColumn.tabletTop}%;
        width: ${toRem(styleConstants.imageDimensionVertical.mobileWidth)};
        @media ${baseVariables.mediaQuery.md} {
          width: ${toRem(styleConstants.imageDimensionVertical.tabletWidth)};
        }
        @media ${baseVariables.mediaQuery.lg} {
          width: ${toRem(styleConstants.imageDimensionVertical.desktopWidth)};
        }
      }
      p {
        &:first-child {
          margin-bottom: ${toRem(8)};
          margin-top: ${toRem(0)};
        }
      }
      .custom-link {
        @media ${baseVariables.mediaQuery.md} {
          display: inline;
        }
        @media ${baseVariables.mediaQuery.lg} {
          display: block;
        }
      }
      .line-separator {
        display: none;
        @media ${baseVariables.mediaQuery.md} {
          display: inline;
        }
        @media ${baseVariables.mediaQuery.lg} {
          display: none;
        }
      }
      .points-underlined {
        display: inline;
      }
    }
    &-blp {
      &-image {
        width: ${toRem(styleConstants.imageDimensionDefault.mobileWidth)};
        margin-top: ${toRem(styleConstants.oneColumn.mobileTop)};
        @media ${baseVariables.mediaQuery.md} {
          width: ${toRem(styleConstants.imageDimensionDefault.tabletWidth)};
          margin-top: ${toRem(styleConstants.oneColumn.tabletTop)};
        }
        @media ${baseVariables.mediaQuery.lg} {
          width: ${toRem(styleConstants.imageDimensionDefault.desktopWidth)};
          margin-top: ${toRem(styleConstants.oneColumn.desktopTop)};
        }
      }
    }
  }
  .badge-txt-align {
    height: ${toRem(8)};
  }
  .flex-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${baseVariables.mediaQuery.md} {
      br {
        display: none;
      }
    }
  }
  .checkbox-wrapper {
    height: ${toRem(24)};
  }
  .badge-txt-align {
    height: ${toRem(8)};
  }
  .margin-zero {
    p {
      margin: 0;
    }
  }
  .points-underlined {
    font-family: ${baseVariables.font.fontSwissCondensed};
    border-bottom: ${toRem(5)} ${baseVariables.border.borderStyleDefault} ${baseVariables.color.accent10};
    display: block;
    width: fit-content;
    margin: ${toRem(4)} auto ${toRem(8)} auto;
    @media ${baseVariables.mediaQuery.md} {
      margin: ${toRem(8)} auto ${toRem(12)} auto;
    }
    @media ${baseVariables.mediaQuery.lg} {
      margin: 0;
      display: inline;
    }
  }
  .tertiaryLinks_styles {
    a {
      text-decoration: underline;
      font-size: ${toRem(16)};
      font-weight: 500;
      text-underline-offset: ${toRem(2)};
    }
  }
`;
