// Styles for AwardsGalleryRow go here.
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const StyledAwardsGalleryRow = styled.div`
  .award-gallery-year-border {
    height: ${toRem(1)};
    background: ${baseVariables.color.merch60};
    width: ${toRem(32)};
    @media ${baseVariables.mediaQuery.lg} {
      width: ${toRem(48)};
    }
  }
`;
