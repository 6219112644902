import React, { FC } from 'react';
import clsx from 'clsx';

import { Image, RichText } from '@marriott/mi-ui-library';

import { CardArtBadgeProps } from './CardArtBadge.types';
import { StyledCardArtBadge } from './CardArtBadge.styles';

export const CardArtBadge: FC<CardArtBadgeProps> = ({
  badgeDetails,
  imagePath,
  customClass,
  defaultImageText,
  imageClass,
  badgeClass,
}) => {
  return (
    <StyledCardArtBadge>
      <div className={`d-flex flex-column align-items-center justify-content-center ${customClass}`}>
        {badgeDetails && (
          <div>
            <RichText
              customClass={clsx(`t-font-xs m-badge-overlay-merch badge-border-style mx-2 ${badgeClass}`)}
              text={badgeDetails}
              componentId={'card-art-badge'}
            />
          </div>
        )}
        <div className="text-center">
          <Image
            defaultImageURL={imagePath}
            altText={defaultImageText ? defaultImageText : 'defaultimagetxt'}
            customClass={imageClass}
          ></Image>
        </div>
      </div>
    </StyledCardArtBadge>
  );
};
