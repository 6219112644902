export enum IconStatus {
  MemberElite = 'icon-status-member-elite',
  SilverElite = 'icon-status-silver-elite',
  GoldElite = 'icon-status-gold-elite',
  PlatinumElite = 'icon-status-platinum-elite',
  TitaniumElite = 'icon-status-titanium-elite',
  AmbassadorElite = 'icon-status-ambassador-elite',
}

export enum IconColor {
  Member = 'icon-color-member',
  Silver = 'icon-color-silver',
  Gold = 'icon-color-gold',
  Platinum = 'icon-color-platinum',
  Titanium = 'icon-color-titanium',
  Ambassador = 'icon-color-ambassador',
}

export const iconColorMap: Record<IconStatus, IconColor> = {
  [IconStatus.MemberElite]: IconColor.Member,
  [IconStatus.SilverElite]: IconColor.Silver,
  [IconStatus.GoldElite]: IconColor.Gold,
  [IconStatus.PlatinumElite]: IconColor.Platinum,
  [IconStatus.TitaniumElite]: IconColor.Titanium,
  [IconStatus.AmbassadorElite]: IconColor.Ambassador,
};

export type FeatureIcon = `${IconStatus}`;
export type FeatureIconColor = `${IconColor}`;

export enum IconType {
  'brownTickmark' = 'gold',
  'greyTickmark' = 'platinum',
  'blueTickmark' = 'silver',
}

export enum DataType {
  Header = 'header',
  Icons = 'icons',
  Emtpy = 'emptyField',
}
